//  types for map operations
export const SELECT = "SELECT";
export const PLACE_NFT = "PLACE_NFT";
export const LOAD_OWNED = "LOAD_OWNED";
export const CLEAN_OWNED = "CLEAN_OWNED";
export const UPDATE = "UPDATE";
export const EDIT = "EDIT";
export const REMOVE = "REMOVE";
export const REMOVED = "REMOVED";
export const DONE = "DONE";
export const LOAD_ALL = "LOAD_ALL";
export const LOOK = "LOOK";
export const CLICK = "CLICK";
export const DISCONNECT = "DISCONNECT";
export const MAP_LOADED = "MAP_LOADED";

// types for sidebar operations
export const EXPLORE = "EXPLORE";
export const CREATE = "CREATE";
export const SPACE = "SPACE";
export const LOADING = "LOADING_OWNED";
export const PICK_A_SPACE = "PICK_A_SPACE";
export const SPACE_PICKED = "SPACE_PICKED";
export const LOADING_ALL = "LOADING_ALL";
export const SET_SPACE_TAB = "SET_SPACE_TAB";
export const SHOW_ALL_SPACES = "SHOW_ALL_SPACES";
export const ASK_USER_TO_CONNECT = "ASK_USER_TO_CONNECT";
export const ASK_USER_FOR_EMAIL = "ASK_USER_FOR_EMAIL";
export const PLACE_BOX = "PLACE_BOX";

// types for space operations
export const SELECT_SPACE = "SELECT_SPACE";
export const SHOW_SPACE = "SHOW_SPACE";
export const LOAD_OWNED_SPACE = "LOAD_OWNED_SPACE";
export const ADD_OWNED_SPACE = "ADD_OWNED_SPACE";
export const UPDATE_OWNED_SPACE = "UPDATE_OWNED_SPACE";
export const LOADING_SPACE = "LOADING_SPACE";
export const CLEAN_OWNED_SPACE = "CLEAN_OWNED_SPACE";
export const LOAD_USER_BIDS = "LOAD_USER_BIDS";
export const UPDATE_USER_BIDS = "UPDATE_USER_BIDS";
export const ADD_USER_BID = "ADD_USER_BID";
export const LOAD_ALL_SPACES = "LOAD_ALL_SPACES";

// types for account operations
export const SET_CONNECTOR_ID = "SET_CONNECTOR_ID";
export const REMOVE_CONNECTOR_ID = "REMOVE_CONNECTOR_ID";
export const SET_ACCOUNT = "SET_ACCOUNT";

// types for jumpIn operations
export const JUMP_IN = "JUMP_IN";
export const KEY_PRESSED = "KEY_PRESSED";
export const CHECK_IN = "CHECK_IN";

// types for phanton
export const PHANTON = "PHANTON";
export const UPDATE_FUND = "UPDATE_FUND";
export const PHANTON_TAB = "PHANTON_TAB";

// types for place box operations
export const BOX_PLACED = "BOX_PLACED";
export const SET_BOX_COLOR = "SET_BOX_COLOR";
export const PLACE_TAB = "PLACE_TAB";
export const LOAD_ALL_BOXES = "LOAD_ALL_BOXES";
export const DELETE_BOX = "DELETE_BOX";
export const BOX_PLACED_BY_OTHERS = "BOX_PLACED_BY_OTHERS";
export const BOX_DELETED_BY_OTHERS = "BOX_DELETED_BY_OTHERS";
