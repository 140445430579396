import { useMoralis } from "react-moralis";
import { getEllipsisTxt } from "helpers/formatters";
import Blockie from "../Blockie";
import { Button, Card, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import Address from "../Address/Address";
import { getExplorer } from "helpers/networks";
import { connectors } from "./config";
import { customEnable } from "./walletlink";
import coinbaseIcon from "./WalletIcons/coinbase.svg";
import { useDispatch, useSelector } from "react-redux";
import { DISCONNECT, REMOVE_CONNECTOR_ID, SET_ACCOUNT, SET_CONNECTOR_ID, ASK_USER_FOR_EMAIL } from "redux/types";
import MailchimpSubscribe from "react-mailchimp-subscribe";

const styles = {
  account: {
    height: "42px",
    padding: "0 15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "fit-content",
    borderRadius: "12px",
    backgroundColor: "rgb(244, 244, 244)",
    cursor: "pointer",
  },
  text: {
    color: "#343a40",
  },
  connector: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "auto",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
    padding: "20px 5px",
    cursor: "pointer",
  },
  icon: {
    alignSelf: "center",
    fill: "rgb(40, 13, 95)",
    flexShrink: "0",
    marginBottom: "8px",
    height: "30px",
  },
};

const signingMessage = "Welcome to PhantaSpace!";

function Account() {
  const dispatch = useDispatch();
  const { authenticate, enableWeb3, isAuthenticated, chainId, logout, Moralis, user } = useMoralis();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const [isAuthModalVisible, setIsAuthModalVisible] = useState(false);
  const connectorIdInStore = useSelector((state) => state.account.connectorId);
  const ask_user_to_connect = useSelector((state) => state.sidebar.ask_user_to_connect);
  const ask_user_for_email = useSelector((state) => state.sidebar.ask_user_for_email);

  const [authError, setAuthError] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  /**
   * 1) Connect to a Evm
   * 2) Request message to sign using the Moralis Auth Api of moralis (handled on server)
   * 3) Login via parse using the signed message (verification handled on server via Moralis Auth Api)
   */
  const handleAuth = async (provider) => {
    try {
      setAuthError(null);
      setIsAuthenticating(true);

      // Enable web3 to get user address and chain
      await enableWeb3({ throwOnError: true, provider });
      const { account, chainId } = Moralis;

      if (!account) {
        throw new Error("Connecting to chain failed, as no connected account was found");
      }
      if (!chainId) {
        throw new Error("Connecting to chain failed, as no connected chain was found");
      }

      // Get message to sign from the auth api
      const { message } = await Moralis.Cloud.run("requestMessage", {
        address: account,
        chain: parseInt(chainId, 16),
        networkType: "evm",
      });

      // Authenticate and login via parse
      await authenticate({
        signingMessage: message,
        throwOnError: true,
      });
      setIsAuthModalVisible(false);
    } catch (error) {
      setAuthError(error);
    } finally {
      setIsAuthenticating(false);
    }
  };

  useEffect(() => {
    if (ask_user_to_connect) {
      setIsAuthModalVisible(true);
    }
  }, [ask_user_to_connect]);

  useEffect(() => {
    if (ask_user_for_email) {
      setIsEmailModalVisible(true);
    }
  }, [ask_user_for_email]);

  var account = "";
  if (user) {
    // account = user.attributes.accounts[0];
    account = user.get("ethAddress");
  }

  useEffect(() => {
    if (isAuthenticated) {
      const email = user.get("email");
      const firstName = user.get("firstName");
      const lastName = user.get("lastName");
      const organization = user.get("organization");
      setUserData({ email, firstName, lastName, organization });
      if (!email) {
        dispatch({ type: ASK_USER_FOR_EMAIL, payload: true });
      }
    }
  }, [isAuthenticated]);

  const [userData, setUserData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    organization: "",
  });

  const submitEmail = (event) => {
    event.preventDefault();
    user.set("email", userData.email);
    user.set("firstName", userData.firstName);
    user.set("lastName", userData.lastName);
    user.set("organization", userData.organization);
    user.save();
    setIsEmailModalVisible(false);
  };

  if (!isAuthenticated || !account) {
    return (
      <>
        <div onClick={() => setIsAuthModalVisible(true)}>
          <p style={styles.account}>Connect</p>
        </div>
        <Modal show={isAuthModalVisible} onHide={() => setIsAuthModalVisible(false)}>
          <Modal.Header>
            <Modal.Title>Connect Wallet</Modal.Title>
          </Modal.Header>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
            {connectors.map(({ title, icon, connectorId }, key) => (
              <div
                style={styles.connector}
                key={key}
                onClick={async () => {
                  try {
                    await handleAuth(connectorId);
                    dispatch({ type: SET_CONNECTOR_ID, payload: connectorId });
                  } catch (e) {
                    console.error(e);
                  }
                }}
              >
                <img src={icon} alt={title} style={styles.icon} />
                <h2 style={{ fontSize: "14px" }}>{title}</h2>
              </div>
            ))}

            {/* <div
              style={styles.connector}
              // key={key}
              onClick={async () => {
                try {
                  Moralis.setEnableWeb3(customEnable);
                  await handleAuth('chainlink');
                  dispatch({ type: SET_CONNECTOR_ID, payload: "injected" });
                  setIsAuthModalVisible(false);
                } catch (e) {
                  console.error(e);
                }
              }}
            >
              <img src={coinbaseIcon} alt={"Coinbase"} style={styles.icon} />
              <h2 style={{ fontSize: "14px" }}>Coinbase Wallet</h2>
            </div> */}
          </div>
        </Modal>
      </>
    );
  }

  return (
    <>
      {/* <button
        onClick={async () => {
          try {
            console.log("change")
            await web3._provider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: "0x38" }],
            });
            console.log("changed")
          } catch (e) {
            console.error(e);
          }
        }}
      >
        Hi
      </button> */}

      <div style={styles.account} onClick={() => setIsModalVisible(true)}>
        <Blockie address={account} scale={3} />
        <p style={{ marginLeft: "5px", ...styles.text }}>{getEllipsisTxt(account, 6)}</p>
      </div>
      <Modal show={isModalVisible} onHide={() => setIsModalVisible(false)}>
        <Modal.Header>
          <Modal.Title>Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Address avatar="left" size={6} copyable style={{ fontSize: "20px" }} />
            {/* <div style={{ marginTop: "10px", padding: "0 10px" }}>
              <a href={`${getExplorer(chainId)}/address/${account}`} target="_blank" rel="noreferrer">
                View on Explorer
              </a>
            </div> */}
            <div style={{ marginTop: "10px", padding: "0 10px" }}>
              <a
                className="link-primary"
                role="button"
                onClick={() => {
                  setIsModalVisible(false);
                  setIsEmailModalVisible(true);
                }}
              >
                Edit personal info
              </a>
            </div>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={async () => {
              await logout();
              dispatch({ type: REMOVE_CONNECTOR_ID });
              setIsModalVisible(false);
              dispatch({ type: DISCONNECT });
              dispatch({ type: SET_ACCOUNT, payload: "" });
            }}
          >
            Disconnect
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={isEmailModalVisible} onHide={() => setIsEmailModalVisible(false)}>
        <Modal.Header>
          <Modal.Title>Set your info to receive our latest news</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MailchimpSubscribe
            url={process.env.REACT_APP_MAILCHIMP_URL}
            render={({ subscribe, status, message }) => (
              <div>
                <form>
                  <div className="form-group">
                    <div className="form-row">
                      <div className="col">
                        <input
                          type="text"
                          value={userData.firstName}
                          onChange={(e) => setUserData({ ...userData, firstName: e.target.value })}
                          className="form-control"
                          id="InputFirstName"
                          placeholder="First Name"
                        />
                      </div>
                      <div className="col">
                        <input
                          type="text"
                          value={userData.lastName}
                          onChange={(e) => setUserData({ ...userData, lastName: e.target.value })}
                          className="form-control"
                          id="InputLastName"
                          placeholder="Last Name"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      value={userData.organization}
                      onChange={(e) => setUserData({ ...userData, organization: e.target.value })}
                      className="form-control"
                      id="InputInsittute"
                      placeholder="Organization"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      value={userData.email}
                      onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                      className="form-control"
                      id="InputEmail"
                      aria-describedby="emailHelp"
                      placeholder="Email"
                    />
                    <small id="emailHelp" className="form-text text-muted">
                      We'll never share your email with anyone else.
                    </small>
                  </div>

                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={(e) => {
                      submitEmail(e);
                      subscribe({
                        FN: userData.firstName,
                        LN: userData.lastName,
                        ORG: userData.organization,
                        EMAIL: userData.email,
                      });
                    }}
                  >
                    Submit
                  </button>
                </form>
              </div>
            )}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Account;
